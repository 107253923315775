body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #FFFFFF;
  width: 80vw;
  padding: 0 10vw;
}

h2, h3 {
  text-align: center;
  font-weight: 200;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
  font-weight: 200;
}

p {
  margin: 0.5em 0;
}

button {
  margin: 0 auto;
  background-color: #8241AA;
  color: white;
  border: none;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  border-radius: 0.2em;
}

textarea {
  border-radius: 0.5em;
  font-size: 1em;
  width: 40vw;
  height: 15vh;
  margin: 1vh 0;
}

.App {
  background-color: #FFFFFF;
  min-height: 100vh;
}

.header {
  background-color: #8241AA;
  color: white;
  min-height: 10vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  width: 100vw;
  margin-left: -15vw;
}

.header a {
  text-decoration: none;
  color: white;
}

.title {
  font-family: 'Eczar', serif;
  font-size: 2.5em;
  padding: 2vh 0;
}

.subtitle {
  padding: 2em;
  font-size: 2em;
  font-weight: 100;
}

.center-text {
  text-align: center;
}

.content {
  padding: 2em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  font-weight: 200;
}

.content-padding {
  padding: 2em;
  font-weight: 200;
}

.step-three {
  padding: 0;
  margin: 0;
}

.k-pdf-export h2 {
  text-align: center;
  margin-bottom: 0.8em;
}

.k-pdf-export h4, h5 {
  margin-bottom: 0.2em;
}

.button-align {
  text-align: center;
  padding-bottom: 3em;
}

.card {
  white-space: pre-wrap;
  padding-bottom: 3em;
}

.ways-to-improve {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.improve-columns {
  padding: 2vw;
}

.pdf-column-right {
  padding: 2vh 2vw;
  width: 60vw;
  white-space: pre-wrap;
}

.pdf-column-left {
  padding: 2vh 2vw;
  width: 20vw;
}

/* breakpoint at 1300px */
